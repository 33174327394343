import http from "../http-common";

class mroDictionaryService {
  //#region Download Dictionary List Itme files
  DownloadMRODictionaryTemplate() {
    return http.get(`/MRODictionary/DownloadMRODictionaryTemplate`, {
      responseType: "blob",
    });
  }
  //#endregion

  //#region validating correct file upload
  validateCorrectFileUpload(fileName) {
    return http.get(
      `/MRODictionary/ValidateCorrectFileUpload?FileName=${fileName}`
    );
  }
  //#endregion

  //#region read count of all data from file
  readCountOfAllDataFromFile(fileName) {
    return http.get(
      `/MRODictionary/ReadCountOfAllDataFromFile?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun definitions
  validateAndUploadNounDefinitions(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounDefinitions?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun modifier definitions
  validateAndUploadNounModifierDefinitions(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounModifierDefinitions?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun synonyms
  validateAndUploadNounSynonyms(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounSynonyms?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun modifier attributes
  validateAndUploadNounModifierAttributes(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounModifierAttributes?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun modifier attributes EVVs
  validateAndUploadNounModifierAttributeEVVs(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounModifierAttributeEVVs?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and upload noun modifier mapped UNSPSCs
  validateAndUploadNounModifierMappedUNSPSCs(fileName) {
    return http.post(
      `/MRODictionary/ValidateAndUploadNounModifierMappedUNSPSCs?FileName=${fileName}`
    );
  }
  //#endregion

  //#region validate and update MRO Dictionary
  validateAndUpdateMRODictionary(data) {
    return http.post(`/MRODictionary/ValidateAndUpdateMRODictionary`, data);
  }
  //#endregion

  //#region read MRO dictionaries list
  readMRODictionariesList() {
    return http.get(`/MRODictionary/ReadMRODictionariesList`);
  }
  //#endregion

  //#region download selected MRODictionaryVersionDataFile
  downloadSelectedMRODictionaryVersionDataFile(id, fileName, UserID) {
    return http.get(
      `/MRODictionary/DownloadSelectedMRODictionaryVersionDataFile?MRODictionaryID=${id}&FileName=${fileName}&UserID=${UserID}`,
      {
        responseType: "blob",
      }
    );
  }
  //#endregion

  //#region validate and upload noun modifier mapped UNSPSCs
  deleteSelectedMRODictionaryVersion(id, fileName, UserID) {
    return http.patch(
      `/MRODictionary/DeleteSelectedMRODictionaryVersion?id=${id}&FileName=${fileName}&UserID=${UserID}`
    );
  }
  //#endregion
}

export default new mroDictionaryService();
