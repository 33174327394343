import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import userService from "../../services/user.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import tableFunctions from "../../helpers/tableFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import projectService from "../../services/project.service";
import * as http from "../../http-common";
toast.configure();

class UserList extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.divScrollRef = React.createRef();

    this.moveToCreateUser = this.moveToCreateUser.bind(this);
    this.exportUserListToExcel = this.exportUserListToExcel.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangeManager = this.onChangeManager.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.sortData = this.sortData.bind(this);
    this.displaySortingFields = this.displaySortingFields.bind(this);
    this.displayFilteringField = this.displayFilteringField.bind(this);
    this.onChangefilterValue = this.onChangefilterValue.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clearSearchField = this.clearSearchField.bind(this);
    this.clearSortFields = this.clearSortFields.bind(this);

    ///Component State
    this.state = {
      users: [],
      departments: [],
      selectedDepartment: "(All)",
      managers: [],
      selectedManager: "(All)",
      canAccessCreateUser: false,
      canAccessViewUser: false,
      loading: false,
      index: 30,
      position: 0,
      columns: [],
      selectedColumn: "",
      selectedSort: "",
      isToShowSortingFields: false,
      isToShowFilteringField: false,
      filteredArray: [],
      filterValue: "",
      spinnerMessage: "",
      userArrayForSorting: [],
    };
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Create User");
    this.canUserAccessPage("View User");
  }
  //#endregion

  //#region fetching Users List from Web API
  fetchUsersList() {
    this.setState({
      spinnerMessage: "Please wait while loading User List...",
      loading: true,
    });

    userService
      .getAllUsers(helper.getUser())
      .then((response) => {
        let formattedArray = response.data.map((obj) => ({
          ...obj,
          FullName:
            obj.FirstName +
            " " +
            (obj.MiddleName ? obj.MiddleName + " " : "") +
            obj.LastName,
          IsLockedOut: obj.IsLockedOut === true ? "Yes" : "No",
        }));

        formattedArray = formattedArray.map((obj) => {
          delete obj.Password;
          delete obj.User;
          delete obj.FirstName;
          delete obj.MiddleName;
          delete obj.LastName;

          return obj;
        });
        // console.log(formattedArray, "formattedArray")


        let departments = [
          ...new Set(formattedArray.map((obj) => obj.DepartmentName)),
        ];

        departments = departments.filter(function (el) {
          return el !== "";
        });

        let managers = [
          ...new Set(formattedArray.map((obj) => obj.ManagerName)),
        ];

        managers = managers.filter(function (el) {
          return el !== "";
        });

        this.setState({
          users: formattedArray,
          departments: departments,
          managers: managers,
          loading: false,
          userArrayForSorting: response.data,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Fetching User List page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Create User") {
          this.setState({
            canAccessCreateUser: response.data,
          });
        } else if (pageName === "View User") {
          this.setState({
            canAccessViewUser: response.data,
          });
        }
        this.fetchUsersList();
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Get Selected Department
  onChangeDepartment(e) {
    this.setState(
      {
        selectedDepartment: e.target.value,
        selectedManager: "(All)",
        filterValue: e.target.value,
      },
      () => this.fetchFilteredData(true)
    );
  }
  //#endregion

  //#region Get Selected Department
  onChangeManager(e) {
    this.setState(
      {
        selectedManager: e.target.value,
        filterValue: e.target.value,
      },
      () => this.fetchFilteredData(false)
    );
  }
  //#endregion

  //#region Fetch Filtered Data
  fetchFilteredData(isToLoadManager) {
    let filteredArray = [];

    if (this.state.selectedDepartment !== "(All)") {
      filteredArray = this.state.users.filter(
        (dept) => dept.DepartmentName === this.state.selectedDepartment
      );

      filteredArray = filteredArray.map((users, index) => {
        return {
          ...users,
          SlNo: index + 1,
        };
      });
    } else {
      filteredArray = this.state.users;
    }

    if (this.state.selectedManager !== "(All)") {
      filteredArray = filteredArray.filter(
        (manager) => manager.ManagerName === this.state.selectedManager
      );

      filteredArray = filteredArray.map((users, index) => {
        return {
          ...users,
          SlNo: index + 1,
        };
      });
    }

    this.setState({ filteredArray: filteredArray }, () => {
      if (isToLoadManager) {
        let managers = [
          ...new Set(this.state.filteredArray.map((obj) => obj.ManagerName)),
        ];

        managers = managers.filter(function (el) {
          return el !== "";
        });

        this.setState({
          managers: managers,
        });
      }
    });
  }
  //#endregion

  //#region Scroll to Top
  scrollToTop = () => {
    this.divScrollRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  //#endregion

  //#region Sort Functions
  //#region Display Sorting Fields
  displaySortingFields() {
    let columns = Object.keys(this.state.users[0]).slice(1);

    this.setState((previousState) => ({
      isToShowSortingFields: !previousState.isToShowSortingFields,
      selectedColumn: "",
      selectedSort: "",
      columns: columns,
      filterValue: "",
      isToShowFilteringField: false,
      selectedDepartment: "(All)",
      selectedManager: "(All)",
    }));
  }
  //#endregion

  //#region Selecting the sort column
  onChangeColumn(e) {
    this.setState({
      selectedColumn: e.target.value,
      selectedSort: "",
    });
  }
  //#endregion

  //#region On Change Sort
  onChangeSortOrder(e) {
    this.setState(
      {
        selectedSort: e.target.value,
      },
      () => this.sortData()
    );
  }
  //#endregion

  //#region Sort Data based on column and order
  sortData() {
    let sortedArray = [];
    let column =
      this.state.selectedColumn !== "" ? this.state.selectedColumn : "SlNo";
    const selectedSort =
      this.state.selectedSort !== "" ? this.state.selectedSort : "ascending";
    let numberColumns = ["SlNo", "UserID"];

    sortedArray = tableFunctions.sortData(
      this.state.users,
      column,
      selectedSort,
      numberColumns,
      []
    );

    if (this.state.selectedColumn !== "SlNo") {
      sortedArray = sortedArray.map((users, index) => {
        return {
          ...users,
          SlNo: index + 1,
        };
      });
    }

    this.setState({ users: sortedArray });
  }
  //#endregion

  //#region  Clear Sort
  clearSortFields() {
    let formattedArray = this.state.userArrayForSorting.map((obj) => ({
      ...obj,
      FullName: obj.FirstName + " " + obj.MiddleName + " " + obj.LastName,
      IsLockedOut: obj.IsLockedOut === true ? "Yes" : "No",
    }));

    formattedArray = formattedArray.map((obj) => {
      delete obj.Password;
      delete obj.User;
      delete obj.FirstName;
      delete obj.MiddleName;
      delete obj.LastName;

      return obj;
    });
    this.setState(
      { selectedColumn: "", selectedSort: "", users:formattedArray },
    );
  }
  //#endregion
  //#endregion

  //#region Filter Functions
  //#region Display Filtering Field
  displayFilteringField() {
    this.setState((previousState) => ({
      isToShowFilteringField: !previousState.isToShowFilteringField,
      filterValue: "",
      isToShowSortingFields: false,
      selectedDepartment: "(All)",
      selectedManager: "(All)",
    }));
  }
  //#endregion

  //#region on change filter value
  onChangefilterValue(e) {
    this.setState({ filterValue: e.target.value }, () =>
      this.getFilteredRows()
    );
  }
  //#endregion

  //#region get filtered rows
  getFilteredRows() {
    let filteredArray = tableFunctions.filterArray(
      this.state.users,
      this.state.filterValue
    );

    filteredArray = filteredArray.map((users, index) => {
      return {
        ...users,
        SlNo: index + 1,
      };
    });

    this.setState({ filteredArray: filteredArray });
  }
  //#endregion

  //#region Clear Search
  clearSearchField() {
    this.setState({
      filterValue: "",
    });
  }
  //#endregion
  //#endregion

  //#region Handle Scroll
  handleScroll(e) {
    var currentHeight = e.currentTarget.scrollTop;
    var maxScrollPosition =
      e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

    this.setState({ position: currentHeight });

    if ((currentHeight / maxScrollPosition) * 100 > 90) {
      let curIndex = this.state.index + 20;
      this.setState({ index: curIndex });
    }
  }
  //#endregion

  //#region Redirect to Create User Page
  moveToCreateUser() {
    this.props.history.push("/admin/CreateUser");
  }
  //#endregion

  //#region Export User List to Excel
  exportUserListToExcel() {
    this.setState({
      spinnerMessage: "Please wait while exporting user list to excel...",
      loading: true,
    });

    let fileName = "User List.xlsx";

    userService
      .exportUserListToExcel(
        this.state.selectedDepartment,
        this.state.selectedManager
      )
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  // #region service call for reset userCredential
  resetCredential = (userId) => {
    userService
      .resetUserCredential(userId, helper.getUser())
      .then((response) => {
        if(response.data === "SUCCESS"){
          toast.success("User credential reset successfully...!");
          this.fetchUsersList()
        } else {
          toast.error(response.Message, { autoClose: false });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  // #endregion

  // #region display profile
  displayProfile = async (imgName) => {
    const response = await projectService.downloadFile(imgName, "profilephoto");
    if(response){
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // console.log("Imahge Name:", imgName);
      // console.log("File:", fileURL);
    return fileURL;
    }
};
  // #endregion

  //#region UI
  render() {
    const canAccessCreateUser = this.state.canAccessCreateUser;
    const canAccessViewUser = this.state.canAccessViewUser;

    const data = this.state.users.slice(0, this.state.index);
    const filteredData = this.state.filteredArray.slice(0, this.state.index);

    const UsersListColumns = [
      {
        dataField: "SlNo",
        text: "Sl No",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "UserID",
        text: "User ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "FullName",
        text: "Full Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "left",
      },
      {
        dataField: "UserName",
        text: "User Name",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "center",
        classes: canAccessViewUser ? "demo-key-row1" : "",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            canAccessViewUser &&
              this.props.history.push({
                pathname: "/admin/ViewUser",
                state: row.UserID,
              });
          },
        },
      },
      {
        dataField: "PhotoFileName",
        text: "Photo",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        formatter: (cell, row, rowIndex, extraData) => {
          if(row.PhotoFileName){
            // const imgTag = this.displayProfile(row.PhotoFileName);
            return ( <><img src={`${http.baseURL}/project/downloadfile?FileName=${row.PhotoFileName}&FileType=profilephoto`} alt="" width="30" height="30" className="profile-img" /></> )
        }},
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Email",
        text: "Email ID",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "DepartmentName",
        text: "Department",
        align: "Center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
      },
      {
        dataField: "ManagerName",
        text: "Manager",
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
      },
      {
        dataField: "IsLockedOut",
        text: "Is Locked Out?",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "RelievingDate",
        text: "Relieving Date",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        formatter: (cell, row, rowIndex, extraData) => (
          <div>{row.RelievingDate ?
            <>
            {moment(new Date(row.RelievingDate)).format("DD-MM-YYYY")}
            </> : ""}
          </div>
        ),
        headerAlign: "center",
        align: "center",
      },
      
      {
        dataField: "UserCredentialReset",
        text: "User Credential Reset",
        headerStyle: {
          backgroundColor: "#f2f8fb",
          minWidth: "max-content",
        },
        style: {
          whiteSpace: "nowrap",
          width: "max-content",
        },
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {(row.IsLockedOut === "Yes" && !row.RelievingDate) && 
            <div onClick={() => this.resetCredential(row.UserID)} style={{cursor: "pointer"}}><i class="fa fa-lock" aria-hidden="true"></i></div> }
          </div>
        ),
        headerAlign: "center",
        align: "center",
      },
    ];

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Admin</span>
            <span>Users-List</span>
          </div>
          <h4>
            Users List{" "}
            {canAccessCreateUser && (
              <span className="icon-size">
                <i
                  className="fa fa-plus text-primary pointer"
                  onClick={this.moveToCreateUser}
                  title="Add New User"
                ></i>
              </span>
            )}
          </h4>
          <ToolkitProvider
            keyField="UserID"
            data={this.state.filterValue === "" ? data : filteredData}
            columns={UsersListColumns}
          >
            {(props) => (
              <div>
                <div className="row mg-b-10">
                {!this.state.isToShowSortingFields &&
                    !this.state.isToShowFilteringField && (
                      <div className="col-md-8 row row-sm mg-b-5 mg-t-5">
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-md-4 mg-t-5">
                              <b>Department</b>
                            </div>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                id="department"
                                name="department"
                                placeholder="--Select--"
                                value={this.state.selectedDepartment}
                                onChange={this.onChangeDepartment}
                              >
                                <option value="(All)">(All)</option>
                                {this.state.departments.map((department) => (
                                  <option key={department}>{department}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg mg-t-10 mg-lg-t-0">
                          <div className="row">
                            <div className="col-md-4 mg-t-5">
                              <b>Manager</b>
                            </div>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                id="manager"
                                name="manager"
                                placeholder="--Select--"
                                value={this.state.selectedManager}
                                onChange={this.onChangeManager}
                              >
                                <option value="(All)">(All)</option>
                                {this.state.managers.map((manager) => (
                                  <option key={manager}>{manager}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row mg-b-10">

                  <div className="col-md-10" style={{ whiteSpace: "nowrap" }}>
                    <div className="row">
                      {this.state.isToShowSortingFields && (
                        <>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-3 mg-t-7">
                                <label htmlFor="sortColumn">Column:</label>
                              </div>
                              <div className="col-lg">
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedColumn}
                                  onChange={this.onChangeColumn}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.columns.map((col) => (
                                    <option key={col}>{col}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row m-0 p-0">
                              <div className="col-md-3 mg-t-7">
                                <label htmlFor="sortOrder">Order:</label>
                              </div>
                              <div className="col-lg">
                                <select
                                  className="form-control mg-l-5"
                                  value={this.state.selectedSort}
                                  onChange={this.onChangeSortOrder}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ascending">Ascending</option>
                                  <option value="descending">Descending</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div>
                              <span
                                className="btn btn-primary pd-b-5"
                                onClick={this.clearSortFields}
                                title="Clear Sort Fields"
                              >
                                <i className="far fa-window-close"></i>
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.isToShowFilteringField && (
                        <>
                            <div className="row  m-0 p-0" >
                              <div className="col-md-1 mg-t-7">
                                <label htmlFor="search">Search:</label>
                              </div>
                              <div className="col-lg pd-r-10">
                                <input
                                  type="text"
                                  className="form-control mg-l-5"
                                  maxLength="20"
                                  value={this.state.filterValue}
                                  onChange={this.onChangefilterValue}
                                />
                              </div>
                              <div>
                                <span
                                  className="btn btn-primary pd-b-5"
                                  onClick={this.clearSearchField}
                                >
                                  <i
                                    className="far fa-window-close"
                                    title="Clear Filter"
                                  ></i>
                                </span>
                              </div>
                            </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-md-2"
                    style={{ textAlign: "end", marginTop: "10px" }}
                  >
                    <i
                      className="fas fa-exchange-alt fa-rotate-90 pointer"
                      title={
                        this.state.isToShowSortingFields
                          ? "Hide Sort"
                          : "Show Sort"
                      }
                      onClick={this.displaySortingFields}
                    ></i>
                    {!this.state.isToShowFilteringField ? (
                      <i
                        className="fas fa-filter pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Show Filter"
                      ></i>
                    ) : (
                      <i
                        className="fas fa-funnel-dollar pointer mg-l-10"
                        onClick={this.displayFilteringField}
                        title="Hide Filter"
                      ></i>
                    )}
                    <i
                      className="fas fa-file-excel mg-l-10 pointer"
                      style={{ color: "green" }}
                      onClick={this.exportUserListToExcel}
                      title="Export to Excel"
                    ></i>
                  </div>
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    borderBottom: "1px solid #cdd4e0",
                    borderTop: "1px solid #cdd4e0",
                  }}
                  ref={this.divScrollRef}
                  className="userlist-table-height"
                  onScroll={this.handleScroll}
                >
                  <BootstrapTable
                    bootstrap4
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
          {this.state.position > 600 && this.state.filterValue === "" && (
            <div style={{ textAlign: "end" }}>
              <button className="scroll-top" onClick={this.scrollToTop}>
                <div className="arrow up"></div>
              </button>
            </div>
          )}
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default UserList;
