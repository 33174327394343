import React, { Component } from "react";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import projectSubActivityService from "../../services/projectSubActivity.service";
import helper from "../../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddProjectSubActivity extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.
    //add super(props) every single time we define a constructor() function
    //inside a class-based component
    this.onChangeSubActivity = this.onChangeSubActivity.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.reset = this.reset.bind(this);
    this.saveProjectSubActivity = this.saveProjectSubActivity.bind(this);
    // pointing to the props object which doesn’t know the existence of any setState function.
    //setState function is only a property of componentInstance
    //the value of 'this' will always point to componentInstance
    //which has setState as one of its property and it will not throw any TypeError

    //Component State
    this.state = {
      subActivityID: 0,
      subActivity: "",
      isActive: true,
      formErrors: {},
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region Component Mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }
  }
  //#endregion

  //#region Validating the input data
  handleFormValidation() {
    const subActivity = this.state.subActivity.trim();
    let formErrors = {};
    let isValidForm = true;

    //Sub activity
    if (!subActivity) {
      isValidForm = false;
      formErrors["subActivityError"] = "Sub Activity is required";
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Bind control value to state variable
  onChangeSubActivity(e) {
    this.setState({
      subActivity: e.target.value,
    });

    if (e.target.value !== "" || e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region get IsActive value
  onChangeIsActive(e) {
    this.setState({
      isActive: e.target.checked,
    });
  }
  //#endregion

  //#region Save Project Sub Activity
  saveProjectSubActivity = (e) => {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    if (this.handleFormValidation()) {
      this.setState({
        spinnerMessage: "Please wait while adding Project Sub-Activity...",
        loading: true,
      });

      //Bind state data to object
      var data = {
        SubActivityID: this.state.subActivityID,
        SubActivity: this.state.subActivity.trim(),
        IsActive: this.state.isActive,
        UserID: helper.getUser(),
      };

      //Service call
      projectSubActivityService
        .createProjectSubActivity(data)
        .then((response) => {
          toast.success("Sub Activity Added Successfully");
          this.setState(this.initialState);
          this.props.history.push({
            pathname: "/Masters/ProjectSubActivities",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(error.response.data.Message, { autoClose: false });
        });
    }
  };
  //#endregion

  //#region Reset the page
  reset() {
    this.setState(this.initialState);
  }
  //#endregion

  render() {
    const { subActivityError } = this.state.formErrors;

    //#region UI
    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Sub-Activities</span>
          </div>
          <h4>
            Create Project Sub-Activity{" "}
            <span className="icon-size">
              <i
                className="far fa-arrow-alt-circle-left text-primary pointer"
                onClick={() => this.props.history.goBack()}
                title="Back to List"
              ></i>
            </span>
          </h4>
          <div>
            <div className="row row-sm">
              <div className="col-lg">
                <label>
                  Sub-Activity{" "}
                  <span className="text-danger asterisk-size">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="SubSubActivity"
                  tabIndex="2"
                  value={this.state.subActivity}
                  onChange={this.onChangeSubActivity}
                />
                {subActivityError && (
                  <div className="error-message">{subActivityError}</div>
                )}
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0"></div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-lg">
                <label>Is Active?</label>
                <input
                  type="checkbox"
                  className="mg-l-20"
                  tabIndex="3"
                  value={this.state.isActive}
                  onChange={this.onChangeIsActive}
                  checked={this.state.isActive}
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0"></div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-1"></div>
              <div className="col-md-2 mg-t-10 mg-lg-t-0">
                <button
                  id="Save"
                  className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                  tabIndex="4"
                  onClick={this.saveProjectSubActivity}
                >
                  Save
                </button>
              </div>
              <div className="col-md-0.5"></div>
              <div className="col-md-2  mg-t-10 mg-lg-t-0">
                <button
                  className="btn btn-gray-700 btn-block"
                  tabIndex="5"
                  onClick={this.reset}
                  id="Reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
    //#endregion
  }
}

export default AddProjectSubActivity;
