import React, { Component } from "react";
import { Link } from "react-router-dom";
import projectService from "../../services/project.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import Moment from "moment";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewProject extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor()

    this.downloadDeliveryPlanFile = this.downloadDeliveryPlanFile.bind(this);
    this.downloadCustomerInputFile = this.downloadCustomerInputFile.bind(this);
    this.downloadScopeFile = this.downloadScopeFile.bind(this);
    this.downloadGuidelineFile = this.downloadGuidelineFile.bind(this);
    this.downloadChecklistFile = this.downloadChecklistFile.bind(this);

    this.handleYes = this.handleYes.bind(this);
    this.showPopUp = this.showPopUp.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.onChangeProjectCode = this.onChangeProjectCode.bind(this);
    this.changeProjectCode = this.changeProjectCode.bind(this);
    this.showChangeProjectCodeModal =
      this.showChangeProjectCodeModal.bind(this);

    //#region Component State
    this.state = {
      projectID: null,
      customerCode: null,
      projectCode: null,
      projectType: null,
      locationCode: null,
      typeOfInput: null,
      inputCount: null,
      inputCountType: null,
      receivedDate: null,
      receivedFormat: null,
      outputFormat: null,
      plannedStartDate: null,
      deliveryMode: null,
      plannedDeliveryDate: null,
      deliveryPlanFileName: null,
      isResourceBased: null,
      remarks: null,
      customerInputFileName: null,
      scope: null,
      scopeFileName: null,
      guideline: null,
      guidelineFileName: null,
      checklist: null,
      checklistFileName: null,
      emailDate: null,
      emailDescription: null,
      activities: [],
      deliveredCount: 0,
      deliveredDate: "",
      showModal: false,
      canAccessEditProject: false,
      canAccessDeleteProject: false,
      canAccessChangeProjectCode: false,
      showChangeProjectCodeModal: false,
      changedProjectCode: "",
      status: "",
      formErrors: {},
      loading: false,
      modalLoading: false,
      spinnerMessage: "",
      activeTab: null,
      unspscVersion: '',
      mrodictionaryVersion:'',
      department: '',
      createdBy: '',
      isProjectSettingsExist: false,
    };
  }
  //#endregion

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchProjectDetails();
    this.canUserAccessPage("Edit Project");
    this.canUserAccessPage("Delete Project");
    this.canUserAccessPage("Change Project Code");
  }
  //#endregion

  //#region Fetching selected Project details
  fetchProjectDetails() {
    const { state } = this.props.location; // Project ID passed from Project List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Projects");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Project Details...",
      loading: true,
    });

    projectService
      .getProjectDetailsByID(state.ProjectID, helper.getUser())
      .then((response) => {
        this.setState({
          projectID: response.data.ProjectID,
          customerCode: response.data.CustomerCode,
          projectCode: response.data.ProjectCode,
          projectType: response.data.ProjectType,
          locationCode: response.data.LocationCode,
          typeOfInput: response.data.TypeOfInput,
          inputCount: response.data.InputCount,
          inputCountType: response.data.InputCountType,
          receivedDate: response.data.ReceivedDate,
          receivedFormat: response.data.ReceivedFormat,
          outputFormat: response.data.OutputFormat,
          plannedStartDate: response.data.PlannedStartDate,
          deliveryMode: response.data.DeliveryMode,
          plannedDeliveryDate: response.data.PlannedDeliveryDate,
          deliveryPlanFileName: response.data.DeliveryPlanFileName,
          isResourceBased: response.data.IsResourceBased,
          remarks: response.data.Remarks,
          unspscVersion: response.data.UNSPSCVersion,
          mrodictionaryVersion: response.data.MRODictionaryVersion,
          department: response.data.Department,
          createdBy: response.data.CreatedByEmployeeName,
          customerInputFileName: response.data.CustomerInputFileName,
          scope: response.data.Scope,
          scopeFileName: response.data.ScopeFileName,
          guideline: response.data.Guideline,
          guidelineFileName: response.data.GuidelineFileName,
          checklist: response.data.Checklist,
          checklistFileName: response.data.ChecklistFileName,
          emailDate: response.data.EmailDate,
          emailDescription: response.data.EmailDescription,
          activities: response.data.Activities,
          deliveredCount: response.data.DeliveredCount,
          deliveredDate: response.data.DeliveredOn,
          status: response.data.Status,
          isProjectSettingsExist: response.data.IsProjectSettingsExist,
          activeTab: state.activeTab,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });

        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Project page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit Project") {
          this.setState({
            canAccessEditProject: response.data,
          });
        } else if (pageName === "Delete Project") {
          this.setState({
            canAccessDeleteProject: response.data,
          });
        } else if (pageName === "Change Project Code") {
          this.setState({
            canAccessChangeProjectCode: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Delivery Plan File
  downloadDeliveryPlanFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading delivery plan file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.deliveryPlanFileName, "deliveryplanfile")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.deliveryPlanFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Customer Input File
  downloadCustomerInputFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading customer input file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.customerInputFileName, "customerinputfile")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.customerInputFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Scope File
  downloadScopeFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading scope file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.scopeFileName, "scope")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.scopeFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Guideline File
  downloadGuidelineFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading guideline file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.guidelineFileName, "guidelines")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.guidelineFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Downloading Checklist File
  downloadChecklistFile() {
    this.setState({
      spinnerMessage: "Please wait while downloading checklist file...",
      loading: true,
    });

    projectService
      .downloadFile(this.state.checklistFileName, "checklist")
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.state.checklistFileName);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region Rendering Table Header data
  renderTableHeader() {
    return (
      <tr>
        <th className="bg-white">Project Activity</th>
        <th className="text-center bg-white">No. of SKUs</th>
        <th className="text-center bg-white">Prod Target</th>
        <th className="text-center bg-white">QC Target</th>
        <th className="text-center bg-white">QA Target</th>
        <th className="text-center bg-white">Allocated Count</th>
      </tr>
    );
  }
  //#endregion

  //#region Rendering Table body data
  renderTableData() {
    return this.state.activities.map((activity) => {
      const {
        Activity,
        NoOfSKUs,
        ProductionTarget,
        QCTarget,
        QATarget,
        AllocatedCount,
      } = activity;
      return (
        <tr key={Activity}>
          <td>{Activity}</td>
          <td align="center">{NoOfSKUs}</td>
          <td align="center">{ProductionTarget}</td>
          <td align="center">{QCTarget}</td>
          <td align="center">{QATarget}</td>
          <td align="center">{AllocatedCount}</td>
        </tr>
      );
    });
  }
  //#endregion

  //#region Close modal Pop Up
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion

  //#region Display Modal Pop up
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region Delete Project
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting the project...",
      loading: true,
    });

    projectService
      .deleteProject(this.state.projectID, helper.getUser())
      .then(() => {
        this.setState({ showModal: false });
        toast.success("Project Deleted Successfully");
        this.props.history.push({
          pathname: "/Projects",
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region Show Change Project Code Modal
  showChangeProjectCodeModal() {
    this.setState({
      showChangeProjectCodeModal: true,
      changedProjectCode: "",
      formErrors: {},
    });
  }
  //#endregion

  //#region On Change Project Code
  onChangeProjectCode(e) {
    this.setState({
      changedProjectCode: e.target.value,
    });

    if (e.target.value !== "" || e.target.value !== null)
      this.setState({ formErrors: {} });
  }
  //#endregion

  //#region  Validating the input data
  handleProjectValidation() {
    const projectCode = this.state.changedProjectCode.trim();
    let formErrors = {};
    let isValidForm = true;
    const re = /^\d+$/;

    //Project Code
    if (!projectCode) {
      isValidForm = false;
      formErrors["projectCodeError"] = "Project Code is required";
    }

    if (projectCode === this.state.projectCode) {
      isValidForm = false;
      formErrors["projectCodeError"] =
        "Change To Project Code cannot be same as existing Project Code";
    }

    //Project Code contains PL
    if (projectCode) {
      let upperCaseChangedProjectCode = projectCode.toUpperCase();
      let upperCaseProjectCode = this.state.projectCode.toUpperCase();
      if (upperCaseProjectCode.startsWith("PL")) {
        if (!upperCaseChangedProjectCode.startsWith("PL")) {
          isValidForm = false;
          formErrors["projectCodeError"] =
            "Project Code should start with PL for Pilot Projects";
        } else if (projectCode.length !== 5) {
          isValidForm = false;
          formErrors["projectCodeError"] =
            "Project Code must be 5 Characters for Pilot Projects";
        } else if (upperCaseChangedProjectCode === "PL000") {
          isValidForm = false;
          formErrors["projectCodeError"] = "Invalid Change To Project Code";
        }
      } else if (!re.test(projectCode)) {
        isValidForm = false;
        formErrors["projectCodeError"] =
          "Project Code should contain only numbers";
      } else if (projectCode.length !== 3) {
        isValidForm = false;
        formErrors["projectCodeError"] = "Project Code must have 3 Characters";
      } else if (projectCode === "000") {
        isValidForm = false;
        formErrors["projectCodeError"] = "Invalid Change To Project Code";
      }
    }

    this.setState({ formErrors: formErrors });
    return isValidForm;
  }
  //#endregion

  //#region Save Project Code
  changeProjectCode() {
    if (this.handleProjectValidation()) {
      this.setState({
        spinnerMessage: "Please wait while changing Project Code...",
        modalLoading: true,
      });

      projectService
        .changeProjectCode(
          this.state.customerCode,
          this.state.projectCode,
          this.state.changedProjectCode,
          helper.getUser()
        )
        .then(() => {
          this.setState({
            modalLoading: false,
            showChangeProjectCodeModal: false,
          });
          toast.success("Project Code Channged Successfully");
          this.props.history.push("/projects");
        })
        .catch((e) => {
          this.setState({
            modalLoading: false,
          });
          toast.error(e.response.data.Message, { autoClose: false });
        });
    }
  }
  //#endregion

  render() {
    const canAccessEditProject = this.state.canAccessEditProject;
    const canAccessDeleteProject = this.state.canAccessDeleteProject;
    const canAccessChangeProjectCode = this.state.canAccessChangeProjectCode;

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="container">
            <div className="az-content-breadcrumb mg-t-20">
              <span>Projects</span>
              <span>View Project</span>
            </div>
            <h4>
              View Project{" "}
              <span className="icon-size">
                <Link
                  to={{
                    pathname: "/Projects",
                    state: {
                      activeTab: this.state.activeTab,
                    },
                  }}
                >
                  <i
                    className="far fa-arrow-alt-circle-left text-primary pointer"
                    tabIndex="1"
                    title="Back to List"
                  ></i>
                </Link>
              </span>
            </h4>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Customer Code</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.customerCode}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Project Code</label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.projectCode}</label>
                    </div>
                    <div className="col-sm-4">
                      {canAccessChangeProjectCode &&
                        this.state.deliveredCount === 0 && (
                          <button
                            className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            onClick={this.showChangeProjectCodeModal}
                          >
                            Change Project Code
                          </button>
                        )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Location </label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.locationCode}</label>
                    </div>
                    <div className="col-sm-5 row">
                      {this.state.deliveredCount > 0 && (
                        <>
                          <label className="mg-r-70">Delivered Date</label>
                          <label>
                            {Moment(this.state.deliveredDate).format(
                              "DD-MMM-yyyy"
                            )}
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Project Type </label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.projectType}</label>
                    </div>
                    <div className="col-sm-5 row">
                      {this.state.deliveredCount > 0 && (
                        <>
                          <label className="mg-r-70">Delivered Count</label>
                          <label>{this.state.deliveredCount}</label>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Type of Input</label>
                    </div>
                    <div className="col-sm-4">
                      <label>{this.state.typeOfInput}</label>
                    </div>
                    <div className="col-sm-5 row">
                      <>
                        <label className="mg-r-120">Status</label>
                        <label>{this.state.status}</label>
                      </>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Input Count</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.inputCount}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Input Count Type</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.inputCountType}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Received Date</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>
                        {this.state.receivedDate &&
                          Moment(this.state.receivedDate).format(
                            "DD-MMM-yyyy"
                          )}{" "}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Received Format</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.receivedFormat}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Output Format</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.outputFormat}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Planned Start Date</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>
                        {this.state.plannedStartDate &&
                          Moment(this.state.plannedStartDate).format(
                            "DD-MMM-yyyy"
                          )}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Delivery Mode</label>
                    </div>
                    <div className="col-sm-9 ">
                      <label>{this.state.deliveryMode}</label>
                    </div>
                  </div>
                  <hr />
                  {this.state.deliveryMode === "Single" ? (
                    <div className="row">
                      <div className="col-sm-3">
                        <label>Planned Delivery Date</label>
                      </div>
                      <div className="col-sm-9">
                        <label>
                          {this.state.plannedDeliveryDate !== null
                            ? Moment(this.state.plannedDeliveryDate).format(
                                "DD-MMM-yyyy"
                              )
                            : ""}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="row align-items-center">
                      <div className="col-sm-3">
                        <label>Delivery Plan File</label>
                      </div>
                      <div className="col-sm-9">
                        <label>
                          <Link to="#/" onClick={this.downloadDeliveryPlanFile}>
                            {this.state.deliveryPlanFileName}
                          </Link>
                        </label>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Is Resource Based?</label>
                    </div>
                    <div className="col-sm-9">
                      <label>
                        {this.state.isResourceBased === true ? "Yes" : "No"}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Customer Input File</label>
                    </div>
                    <div className="col-sm-9">
                      <label>
                        <Link to="#/" onClick={this.downloadCustomerInputFile}>
                          {this.state.customerInputFileName}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Remarks</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.remarks}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Scope</label>
                    </div>
                    <div className="col-sm-5">
                      <label>{this.state.scope}</label>
                    </div>
                    <div className="col-sm-4">
                      <Link to="#/" onClick={this.downloadScopeFile}>
                        {this.state.scopeFileName}
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>GuideLine</label>
                    </div>
                    <div className="col-sm-5">
                      <label>{this.state.guideline}</label>
                    </div>
                    <div className="col-sm-4">
                      <Link to="#/" onClick={this.downloadGuidelineFile}>
                        {this.state.guidelineFileName}
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <label>Checklist</label>
                    </div>
                    <div className="col-sm-5">
                      <label>{this.state.checklist}</label>
                    </div>
                    <div className="col-sm-4">
                      <Link to="#/" onClick={this.downloadChecklistFile}>
                        {this.state.checklistFileName}
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Email Date</label>
                    </div>
                    <div className="col-sm-9">
                      <label>
                        {this.state.emailDate !== null
                          ? Moment(this.state.emailDate).format("DD-MMM-yyyy")
                          : ""}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Email Desription</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.emailDescription}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>UNSPSC Version</label>
                    </div>
                    <div className="col-sm-3">
                      <label>{this.state.unspscVersion}</label>
                    </div>
                    <div className="col-sm-3">
                      <label>MRO Dictionary Version</label>
                    </div>
                    <div className="col-sm-3">
                      <label>{this.state.mrodictionaryVersion}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Department</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.department}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Created By</label>
                    </div>
                    <div className="col-sm-9">
                      <label>{this.state.createdBy}</label>
                    </div>
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table mg-t-20">
                      <thead>{this.renderTableHeader()}</thead>
                      <tbody>{this.renderTableData()}</tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-sm-1"></div>
                    {canAccessEditProject &&
                      this.state.deliveredCount === 0 && (
                        <div className="col-md-4">
                          <Link
                              to={{
                                pathname: "/Projects/EditProject",
                                state: this.state.projectID,
                              }}
                              className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            >
                              Edit
                            </Link>
                          {/* {this.state.isProjectSettingsExist ? (
                            <Button
                              disabled={this.state.isProjectSettingsExist}
                              className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            >
                              Edit
                            </Button>
                          ) : (
                            <Link
                              to={{
                                pathname: "/Projects/EditProject",
                                state: this.state.projectID,
                              }}
                              className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            >
                              Edit
                            </Link>
                          )} */}
                        </div>
                      )}
                    <div className="col-sm-1"></div>
                    {canAccessDeleteProject &&
                      this.state.deliveredCount === 0 && (
                        <div className="col-md-4">
                          <button
                            className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                            onClick={this.showPopUp}
                            disabled={this.state.isProjectSettingsExist}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Project?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.showChangeProjectCodeModal}
              onHide={() =>
                this.setState({ showChangeProjectCodeModal: false })
              }
              dialogClassName="modal-width-produpload"
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              enforceFocus={false}
              //dialogClassName="border border-secondary"
            >
              <LoadingOverlay
          active={this.state.modalLoading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
                <Modal.Header>
                  <Modal.Title id="changeProjectCodeModal">
                    Change Project Code
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row row-sm">
                    <div className="col-md-6 text-nowrap">
                      <label htmlFor="CustomerCode">
                        <b>Customer Code</b>
                      </label>
                    </div>
                    <div className="col-md-5 mg-t-7 mg-l-2">
                      <p id="CustomerCode" name="CustomerCode">
                        {this.state.customerCode}
                      </p>
                    </div>
                  </div>
                  <div className="row row-sm">
                    <div className="col-md-6 text-nowrap">
                      <label htmlFor="ProjectCode">
                        <b>Project Code</b>
                      </label>
                    </div>
                    <div className="col-md-5 mg-t-7 mg-l-2">
                      <p id="ProjectCode" name="ProjectCode">
                        {this.state.projectCode}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <b>Change To Project Code</b>{" "}
                      <span className="text-danger asterisk-size">*</span>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="5"
                        id="ChangeToProjectCode"
                        name="ChangeToProjectCode"
                        tabIndex="1"
                        value={this.state.changedProjectCode}
                        onChange={this.onChangeProjectCode}
                      />
                      <div className="error-message">
                        {this.state.formErrors["projectCodeError"]}
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mg-t-30">
                    <div className="col-md-3"></div>
                    <div className="col-md-3 mg-t-10 mg-lg-t-0">
                      <span
                        className="mg-t-10 mg-md-t-0 btn btn-gray-700 btn-block"
                        onClick={this.changeProjectCode}
                      >
                        Change
                      </span>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-3  mg-t-10 mg-lg-t-0">
                      <span
                        className="btn btn-gray-700 btn-block"
                        tabIndex="28"
                        onClick={() =>
                          this.setState({ showChangeProjectCodeModal: false })
                        }
                        id="Cancel"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </Modal.Body>
              </LoadingOverlay>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewProject;
