import React, { Component } from "react";
import { Link } from "react-router-dom";
import projectSubActivityService from "../../services/projectSubActivity.service";
import accessControlService from "../../services/accessControl.service";
import helper from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ViewProjectSubActivity extends Component {
  constructor(props) {
    super(props); //reference to the parents constructor() function.

    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.showPopUp = this.showPopUp.bind(this);

    //state
    this.state = {
      ProjectSubActivities: [
        {
          ProjectSubActivityID: 0,
          SubActivity: "",
          IsActive: true,
        },
      ],
      showModal: false,
      canAccessEditProjectSubActivity: false,
      canAccessDeleteProjectSubActivity: false,
      loading: false,
      spinnerMessage: "",
    };
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.canUserAccessPage("Edit Project SubActivity");
    this.canUserAccessPage("Delete Project SubActivity");
    this.fetchProjectSubActivity();
  }
  //#endregion

  //#region Fetching selected Project Sub Activity details
  fetchProjectSubActivity() {
    const { state } = this.props.location; // Project Sub Activity ID passed from Project Sub Activity List Page
    if (state === 0 || state === null || state === undefined) {
      this.props.history.push("/Masters/ProjectSubActivities");
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while loading Project SubActivity...",
      loading: true,
    });

    projectSubActivityService
      .getProjectSubActivity(state, helper.getUser())
      .then((response) => {
        this.setState({
          ProjectSubActivities: response.data,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region fetching Customer page access
  canUserAccessPage(pageName) {
    accessControlService
      .CanUserAccessPage(helper.getUser(), pageName)
      .then((response) => {
        if (pageName === "Edit Project SubActivity") {
          this.setState({
            canAccessEditProjectSubActivity: response.data,
          });
        } else if (pageName === "Delete Project SubActivity") {
          this.setState({
            canAccessDeleteProjectSubActivity: response.data,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //#region modal functions
  //#region show popup
  showPopUp() {
    this.setState({ showModal: true });
  }
  //#endregion

  //#region handle Yes
  handleYes() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.setState({
      spinnerMessage: "Please wait while deleting Project Sub-Activity...",
      loading: true,
    });

    projectSubActivityService
      .deleteProjectSubActivity(
        this.state.ProjectSubActivities.ProjectSubActivityID,
        helper.getUser()
      )
      .then(() => {
        this.setState({ showModal: false, loading: false });
        toast.success("Project Sub Activity Deleted Successfully");
        this.props.history.push({
          pathname: "/Masters/ProjectSubActivities",
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
        this.handleNo();
      });
  }
  //#endregion

  //#region handle No
  handleNo() {
    this.setState({ showModal: false });
  }
  //#endregion
  //#endregion

  //#region UI
  render() {
    const { ProjectSubActivityID, SubActivity, IsActive } =
      this.state.ProjectSubActivities;

    const canAccessEditProjectSubActivity =
      this.state.canAccessEditProjectSubActivity;
    const canAccessDeleteProjectSubActivity =
      this.state.canAccessDeleteProjectSubActivity;

    return (
      <div className="pro-main-display">
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content-breadcrumb">
            <span>Master</span>
            <span>Sub-Activities</span>
          </div>
          <h4>
            View Project Sub-Activity{" "}
            <span className="icon-size">
              {" "}
              <Link to="/Masters/ProjectSubActivities" title="Back to List">
                <i className="far fa-arrow-alt-circle-left"></i>
              </Link>
            </span>
          </h4>
          <br />
          <div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm mg-b-5">
                  <div className="col-md-4">
                    <b>Project Sub Activity ID</b>
                  </div>
                  <div className="col-md-8">
                    <p>{ProjectSubActivityID}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <b>Sub-Activity</b>
                  </div>
                  <div className="col-md-8">
                    <p>{SubActivity}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <b>Is Active?</b>
                  </div>
                  <div className="col-md-8">
                    {IsActive === false && <p>No</p>}
                    {IsActive === true && <p>Yes</p>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-0.5"></div>
              {canAccessEditProjectSubActivity && (
                <div className="col-md-2">
                  <Link
                    to={{
                      pathname: "/Masters/EditProjectSubActivity",
                      state: ProjectSubActivityID, // passing Project Activity to Edit Project Activity Page
                    }}
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                  >
                    Edit
                  </Link>
                </div>
              )}
              <div className="col-md-0.5"></div>
              {canAccessDeleteProjectSubActivity && (
                <div className="col-md-2">
                  <button
                    className="mg-t-10 mg-md-t-0 btn  btn-gray-700 btn-block"
                    onClick={this.showPopUp}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <Modal
              show={this.state.showModal}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={this.handleNo}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header>
                <Modal.Title>Delete Project Sub Activity</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p>Are you sure to delete this Project Sub Activity?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleYes}>
                  Yes
                </Button>
                <Button variant="primary" onClick={this.handleNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
  //#endregion
}

export default ViewProjectSubActivity;
