import React, { Component } from "react";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";

import CustomerList from "./Customer/CustomerList1";
import AddCustomer from "./Customer/AddCustomer1";
import ViewCustomer from "./Customer/ViewCustomer1";
import EditCustomer from "./Customer/EditCustomer1";

import ProjectActivityList from "./ProjectActivity/ProjectActivityList1";
import AddProjectActivity from "./ProjectActivity/AddProjectActivity1";
import ViewProjectActivity from "./ProjectActivity/ViewProjectActivity1";
import EditProjectActivity from "./ProjectActivity/EditProjectActivity1";

import ProjectSubActivityList from "./ProjectSubActivity/ProjectSubActivityList";
import AddProjectSubActivity from "./ProjectSubActivity/AddProjectSubActivity";
import EditProjectSubActivity from "./ProjectSubActivity/EditProjectSubActivity";
import InputOutputFormatList from "./InputOutputFormat/InputOutputFormatList";
import AddInputOutputFormat from "./InputOutputFormat/AddInputOutputFormat";
import EditInputOutputFormat from "./InputOutputFormat/EditInputOutputFormat";
import ItemStatusList from "./ItemStatus/ItemStatusList";
import AddItemStatus from "./ItemStatus/AddItemStatus";
import EditItemStatus from "./ItemStatus/EditItemStatus";
import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import viewProjectSubActivity from "./ProjectSubActivity/ViewProjectSubActivity";
import ViewInputOutputFormat from "./InputOutputFormat/ViewInputOutputFormat";
import ViewItemStatus from "./ItemStatus/ViewItemStatus";
import GenericActivityList from "./GenericActivity/GenericActivityList";
import CreateGenericActivity from "./GenericActivity/CreateGenericActivity";
import ViewGenericActivity from "./GenericActivity/ViewGenericActivity";
import EditGenericActivity from "./GenericActivity/EditGenericActivity";

import CommunicationModeList from "./CommunicationMode/CommunicationModeList";
import CreateCommunicationMode from "./CommunicationMode/CreateCommunicationMode";
import ViewCommunicationMode from "./CommunicationMode/ViewCommunicationMode";
import EditCommunicationMode from "./CommunicationMode/EditCommunicationMode";

import CustomerFeedbackTypeList from "./CustomerFeedbackType/CustomerFeedbackTypeList";
import CreateCustomerFeedbackType from "./CustomerFeedbackType/CreateCustomerFeedbackType";
import ViewCustomerFeedbackType from "./CustomerFeedbackType/ViewCustomerFeedbackType";
import EditCustomerFeedbackType from "./CustomerFeedbackType/EditCustomerFeedbackType";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./Masters.scss";

//import icons from react icons
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import accessControlService from "../services/accessControl.service";
import helper from "../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Masters extends Component {
  constructor(props) {
    super(props); //reference to the parent constructor

    this.state = {
      accessControl: [],
      menuCollapse: false,
      style: "margin-not-collpased",
      customerPageAccess: false,
      projectActivityPageAccess: false,
      projectSubActivityPageAccess: false,
      inputOutputFormatPageAccess: false,
      itemStatusPageAccess: false,
      genericActivityPageAccess: false,
      communicationModePageAccess: false,
      customerFeedbackTypePageAccess: false,
      loading: false,
      spinnerMessage: "",
    };

    this.initialState = this.state;
  }

  //#region component mount
  componentDidMount() {
    if (!helper.getUser()) {
      this.props.history.push({
        pathname: "/",
      });
      return;
    }

    this.fetchUserRoleAccess();
  }
  //#endregion

  //#region Fetching Logged In User Access
  fetchUserRoleAccess() {
    this.setState({
      spinnerMessage: "Please wait while loading...",
      loading: true,
    });

    accessControlService
      .ReadUserMenuAccessList(helper.getUser(), "Masters")
      .then((response) => {
        this.setState(
          {
            accessControl: response.data,
          },
          () => {
            let customerPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Customer List"
            );

            let projectActivityPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Project Activity List"
            );

            let projectSubActivityPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Project SubActivity List"
            );

            let inputOutputFormatPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Input-Output Format List"
            );

            let itemStatusPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Item Status List"
            );

            let genericActivityPageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Generic Activity List"
            );

            let communicationModePageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Communication Mode List"
            );

            let customerFeedbackTypePageAccess = this.state.accessControl.find(
              (a) => a.PageName === "Customer Feedback Type List"
            );

            this.setState({
              customerPageAccess: customerPageAccess.canAccess,
              projectActivityPageAccess: projectActivityPageAccess.canAccess,
              projectSubActivityPageAccess:
                projectSubActivityPageAccess.canAccess,
              inputOutputFormatPageAccess:
                inputOutputFormatPageAccess.canAccess,
              itemStatusPageAccess: itemStatusPageAccess.canAccess,
              genericActivityPageAccess: genericActivityPageAccess.canAccess,
              communicationModePageAccess:
                communicationModePageAccess.canAccess,
              customerFeedbackTypePageAccess:
                customerFeedbackTypePageAccess.canAccess,
              loading: false,
            });
          }
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  //create a custom function that will change menucollapse state from false to true and true to false
  menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    if (this.state.menuCollapse) {
      this.setState({
        menuCollapse: false,
        style: "margin-not-collpased",
      });
    } else {
      this.setState({
        menuCollapse: true,
        style: "margin-collpased",
      });
    }
  };

  render() {
    const menuCollapse = this.state.menuCollapse;
    const style = this.state.style;
    const customerPageAccess = this.state.customerPageAccess;
    const projectActivityPageAccess = this.state.projectActivityPageAccess;
    const projectSubActivityPageAccess =
      this.state.projectSubActivityPageAccess;
    const inputOutputFormatPageAccess = this.state.inputOutputFormatPageAccess;
    const itemStatusPageAccess = this.state.itemStatusPageAccess;
    const genericActivityPageAccess = this.state.genericActivityPageAccess;
    const communicationModePageAccess = this.state.communicationModePageAccess;
    const customerFeedbackTypePageAccess =
      this.state.customerFeedbackTypePageAccess;

    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {this.state.spinnerMessage}
              </p>
            </div>
          }
        >
          <div className="az-content">
            <div className="container">
              <div id="header" style={{ zIndex: "0" }}>
                {/* collapsed props to change menu size using menucollapse state */}
                <ProSidebar collapsed={menuCollapse}>
                  <SidebarHeader>
                    <div className="logotext">
                      {/* small and big change using menucollapse state */}
                      <p>{menuCollapse ? "Masters" : "Masters"}</p>
                    </div>
                    <div className="closemenu" onClick={this.menuIconClick}>
                      {/* changing menu collapse icon on click */}
                      {menuCollapse ? (
                        <FiArrowRightCircle />
                      ) : (
                        <FiArrowLeftCircle />
                      )}
                    </div>
                  </SidebarHeader>
                  <hr />
                  <SidebarContent>
                    <Menu iconShape="square">
                      {customerPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i>{" "}
                          Customers
                          <Link to="/Masters/Customers"></Link>
                        </MenuItem>
                      )}
                      {projectActivityPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Project
                          Activities
                          <Link to="/Masters/ProjectActivities"></Link>
                        </MenuItem>
                      )}
                      {projectSubActivityPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Project
                          Sub-Activities
                          <Link to="/Masters/ProjectSubActivities"></Link>
                        </MenuItem>
                      )}
                      {inputOutputFormatPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Input /
                          Output Formats
                          <Link to="/Masters/InputOutputFormats"></Link>
                        </MenuItem>
                      )}
                      {itemStatusPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Item
                          Status List <Link to="/Masters/ItemStatusList"></Link>
                        </MenuItem>
                      )}
                      {genericActivityPageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Generic
                          Activities
                          <Link to="/Masters/GenericActivities"></Link>
                        </MenuItem>
                      )}
                      {communicationModePageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i>{" "}
                          Communication Modes
                          <Link to="/Masters/CommunicationModeList"></Link>
                        </MenuItem>
                      )}
                      {customerFeedbackTypePageAccess && (
                        <MenuItem>
                          <i className="fas fa-arrow-circle-right"></i> Customer
                          Feedback Types
                          <Link to="/Masters/CustomerFeedbackTypeList"></Link>
                        </MenuItem>
                      )}
                    </Menu>
                  </SidebarContent>
                </ProSidebar>
              </div>
              <div className={style} style={{ width: "100%" }}>
                <div className="az-content-body d-flex flex-column">
                  <div>
                    <Router>
                      <Switch>
                        <Route path="/Masters" exact>
                          <CustomerList />
                        </Route>
                        <Route
                          path="/Masters/Customers"
                          component={CustomerList}
                        ></Route>
                        <Route
                          path="/Masters/ViewCustomer"
                          component={ViewCustomer}
                        ></Route>
                        <Route
                          path="/Masters/ProjectActivities"
                          component={ProjectActivityList}
                        ></Route>
                        <Route
                          path="/Masters/ProjectSubActivities"
                          component={ProjectSubActivityList}
                        ></Route>
                        <Route
                          path="/Masters/InputOutputFormats"
                          component={InputOutputFormatList}
                        ></Route>
                        <Route
                          path="/Masters/AddCustomer"
                          component={AddCustomer}
                        ></Route>
                        <Route
                          path="/Masters/AddProjectActivity"
                          component={AddProjectActivity}
                        ></Route>
                        <Route
                          path="/Masters/AddProjectSubActivity"
                          component={AddProjectSubActivity}
                        ></Route>
                        <Route
                          path="/Masters/AddInputOutputFormat"
                          component={AddInputOutputFormat}
                        ></Route>
                        <Route
                          path="/Masters/EditCustomer"
                          component={EditCustomer}
                        ></Route>
                        <Route
                          path="/Masters/EditProjectActivity"
                          component={EditProjectActivity}
                        ></Route>
                        <Route
                          path="/Masters/EditProjectSubActivity"
                          component={EditProjectSubActivity}
                        ></Route>
                        <Route
                          path="/Masters/EditInputOutputFormat"
                          component={EditInputOutputFormat}
                        ></Route>
                        <Route
                          path="/Masters/ItemStatusList"
                          component={ItemStatusList}
                        ></Route>
                        <Route
                          path="/Masters/AddItemStatus"
                          component={AddItemStatus}
                        ></Route>
                        <Route
                          path="/Masters/EditItemStatus"
                          component={EditItemStatus}
                        ></Route>
                        <Route
                          path="/Masters/ViewProjectActivity"
                          component={ViewProjectActivity}
                        ></Route>
                        <Route
                          path="/Masters/ViewProjectSubActivity"
                          component={viewProjectSubActivity}
                        ></Route>
                        <Route
                          path="/Masters/ViewInputOutputFormat"
                          component={ViewInputOutputFormat}
                        ></Route>
                        <Route
                          path="/Masters/ViewItemStatus"
                          component={ViewItemStatus}
                        ></Route>
                        <Route
                          path="/Masters/GenericActivities"
                          component={GenericActivityList}
                        ></Route>
                        <Route
                          path="/Masters/CreateGenericActivity"
                          component={CreateGenericActivity}
                        ></Route>
                        <Route
                          path="/Masters/ViewGenericActivity"
                          component={ViewGenericActivity}
                        ></Route>
                        <Route
                          path="/Masters/EditGenericActivity"
                          component={EditGenericActivity}
                        ></Route>
                        <Route
                          path="/Masters/CommunicationModeList"
                          component={CommunicationModeList}
                        ></Route>
                        <Route
                          path="/Masters/CreateCommunicationMode"
                          component={CreateCommunicationMode}
                        ></Route>
                        <Route
                          path="/Masters/ViewCommunicationMode"
                          component={ViewCommunicationMode}
                        ></Route>
                        <Route
                          path="/Masters/EditCommunicationMode"
                          component={EditCommunicationMode}
                        ></Route>
                        <Route
                          path="/Masters/CustomerFeedbackTypeList"
                          component={CustomerFeedbackTypeList}
                        ></Route>
                        <Route
                          path="/Masters/CreateCustomerFeedbackType"
                          component={CreateCustomerFeedbackType}
                        ></Route>
                        <Route
                          path="/Masters/ViewCustomerFeedbackType"
                          component={ViewCustomerFeedbackType}
                        ></Route>
                        <Route
                          path="/Masters/EditCustomerFeedbackType"
                          component={EditCustomerFeedbackType}
                        ></Route>
                      </Switch>
                    </Router>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Masters;
